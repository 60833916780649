<template>
  <div class="container">
    <checklist-appointments class="mb-3" />
    <v-card flat>
      <v-card-title> Agendamentos </v-card-title>
      <v-card-text>
        <calendar />
      </v-card-text>
    </v-card>
    <form-time />
    <v-card class="mt-3" flat>
      <v-card-title class="d-inline-block">
        Agendamentos pendentes de aprovação
        <span class="subtitle-2 d-block">
          Obs: Horário em formato 24h de acordo com seu horário local.
        </span>
      </v-card-title>
      <v-card-text>
        <appointments-to-approve />
      </v-card-text>
    </v-card>
    <subscription-product-type-dialog />
  </div>
</template>

<script>
export default {
  name: "AppointmentsDashboard",
  components: {
    SubscriptionProductTypeDialog: () =>
      import(
        "@/domains/subscriptions/presentation/components/SubscriptionProductTypeDialog"
      ),
    ChecklistAppointments: () =>
      import(
        "@/domains/therapist-settings/presentation/components/Appointments"
      ),
    Calendar: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/Calendar"
      ),
    FormTime: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/FormTime"
      ),
    AppointmentsToApprove: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/ToApprove"
      ),
  },
};
</script>

<style scoped></style>
